<template>
  <div>
    <div class="d-flex flex-wrap">
      <sign-off-card
        v-for="(signOff, idx) in signOffList"
        :key="idx"
        :signOffData="signOff"
        :class="idx != signOffList.length ? 'mr-3' : ''"
        class="pointer mb-2"
      ></sign-off-card>
    </div>
  </div>
</template>
<script>
import SignOffCard from "./components/SignOffCard.vue";
export default {
  components: { SignOffCard },
  data() {
    return {
      signOffList: [
        {
          title: "單位風險導向",
          cont: 14,
          year: "2022",
          no: "2",
          role: "科主管",
          type: "unitOrientation",
        },
        {
          title: "風險類型與項目",
          cont: 1,
          year: "2023",
          no: "1",
          role: "副總稽核",
          type: "riskType",
        },
        {
          title: "配分設定",
          cont: 1,
          year: "2022",
          no: "2",
          role: "副總稽核",
          type: "allocation",
        },
        {
          title: "單位風險導向",
          cont: 12,
          year: "2022",
          no: "2",
          role: "副總稽核",
          type: "unitOrientation",
        },
      ],
    };
  },
};
</script>
