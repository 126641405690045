<template>
  <v-card
    min-width="220"
    class="elevation-3 pa-2"
    @click="
      $router.push(
        `/to-sign-off/${signOffData.type}/${signOffData.year}/${signOffData.no}`
      )
    "
  >
    <v-card-title class="justify-space-between"
      ><p class="text-h4 font-weight-bold fubonBlue--text">
        {{ signOffData.title }}
      </p>
      <p class="text-h4 error--text">{{ signOffData.cont }}</p>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <span>{{ signOffData.year }}-{{ versionText(signOffData.no) }}</span>
        <span class="black--text">{{ signOffData.role }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { versionMixin } from "@/helper";
export default {
  props: {
    signOffData: {
      type: Object,
      default: () => {
        return {
          title: "單位風險導向",
          cont: 14,
          year: "2022",
          no: "2",
          role: "科主管",
        };
      },
    },
  },
  mixins: [versionMixin],
  data() {
    return {};
  },
};
</script>
